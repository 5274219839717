import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "layout"
    }}>{`Layout`}</h1>
    <p><img alt="fixed vertical menu layout" src={require("../img/home1-left-sidebar.jpg")} /></p>
    <p>{`Theme supports 2 different layouts across the entire site:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Fixed Left Sidebar layout`}</strong>{` - Sidebar always appears on most of pages.`}</li>
      <li parentName="ol"><strong parentName="li">{`Default layout`}</strong>{` - Sidebar appears only on the pages required, such as category pages, brands, search pages.`}</li>
    </ol>
    <p>{`You can configure the layout in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Global`}</strong>{` > `}<strong parentName="p">{`Layout`}</strong>{`:`}</p>
    <p><img alt="Theme editor layout" src={require("../img/theme-editor-layout.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      